let SmoothScroll
const SetUpFaqs = ($SmoothScroll) => {
 SmoothScroll = $SmoothScroll;
 let containers = document.querySelectorAll('.concertina');

  for (let j = 0; j < containers.length; j++){
	let container = containers[j];
  	let articles = container.querySelectorAll('.question');
	for (let i = 0; i < articles.length; i++) {
		let article = articles[i];
		let trigger = article.querySelector('.question-title');
		let answer = article.querySelector('.answer');
		let closedHeight = trigger.scrollHeight + 'px';
		article.setAttribute('data-closed', closedHeight);
	
		article.style.height = closedHeight;
		/// single or double opener
		let numStage = article.classList.contains('entry-double') ? 'double' : 'single';
		///
		if(numStage === "double"){
			let readless = answer.querySelector('.read-less-link');
			let paragraphs = answer.querySelectorAll('p');
			let node = document.createElement("a");                 // Create a <li> node
			let textnode = document.createTextNode("Read more"); 
			
			///
			node.classList.add('read-more-link');
			node.appendChild(textnode);  
			paragraphs[0].append('…');
			paragraphs[0].appendChild(node);
			node.onclick = (event) => {
				fullOpen(article,trigger,answer);
			}
			readless.onclick = () => {
				halfOpen(article,trigger,answer);
			}
			trigger.onclick = (event) => {
				
				//article.style.height = article.scrollHeight  + 'px';
				//article.classList.toggle('open');
				initialOpen(article,trigger,answer);
				//is open
				if(article.classList.contains('open')) {
						// total of question + answer
						halfOpen(article,trigger,answer)

				} else {
				// make it the question height only
					close(article,closedHeight);

				}
				
			}
		}
		if(numStage === "single"){
			
			trigger.onclick = (event) => {
				
				//article.style.height = article.scrollHeight  + 'px';
				//article.classList.toggle('open');
				initialOpen(article);
				//is open
				if(!article.classList.contains('full-open')) {
					fullOpen(article,trigger,answer);

				} else {
				// make it the question height only
					close(article,closedHeight);
				}
				
			}
		}


	}
  }
  SetUpEvents();
  CheckHash();
}

const initialOpen = ( $article,$trigger, $answer, $numStage) => {

	$article.style.height = $article.scrollHeight  + 'px';
	$article.classList.toggle('open');
	if($numStage == "single"){
		fullOpen($article,$trigger,$answer);
	}
}

const halfOpen = (article,trigger,answer) => {
	article.style.height = '50vw';
	let rm = article.querySelector('.read-more-link');
	let bb = article.getBoundingClientRect().top;
	let bb2 = rm.getBoundingClientRect().bottom;
	let vw = (window.innerWidth / 2);
	let bbh = (bb2 - bb) + 50;
	console.log('bb', bbh, 'vw', vw);
	let hoh = (vw > bbh) ? vw : bbh;
	article.style.height = hoh + 'px';
	if(article.classList.contains('full-open')) article.classList.remove('full-open');
}

const fullOpen = (article,trigger,answer) => {
	let totalHeight = trigger.scrollHeight + answer.scrollHeight  + 'px';
	article.style.height = totalHeight;
	if(!article.classList.contains('full-open')) article.classList.add('full-open');
}

const close = (article) => {
	let closedHeight =  article.getAttribute('data-closed');
	if(article.classList.contains('full-open')) article.classList.remove('full-open');
	article.style.height =  closedHeight;
	
}



const SetUpEvents = () => {

	['resize','load'].forEach( evt =>  window.addEventListener(evt, requestFrame, false) );
	
	}
	
	function requestFrame () {
	
		window.requestAnimationFrame(() => resizeFaqs())
	 
	  
	}

const resizeFaqs = () => {
	let containers = document.querySelectorAll('.concertina');

	for (let j = 0; j < containers.length; j++){
		let container = containers[j];
     	let articles = container.querySelectorAll('.question');
		for (let i = 0; i < articles.length; i++) {

			let article = articles[i];
			let trigger = article.querySelector('.question-title');
			let answer = article.querySelector('.answer');
			let closedHeight = trigger.scrollHeight + 'px';
	//style removed only if notopen ---- ahah
			if(!article.classList.contains('open') ) article.removeAttribute('style');

			article.setAttribute('data-closed', closedHeight);
			article.style.height = closedHeight;
		
			
			//rsize if fullopen & double or open & single
			if((article.classList.contains('full-open') && article.classList.contains('entry-double')) ||
			(article.classList.contains('open') && article.classList.contains('entry-single')) ){
				let totalHeight = trigger.scrollHeight + answer.scrollHeight  + 'px';
				article.style.height = totalHeight;
			}

			if(article.classList.contains('open') && article.classList.contains('entry-double')){
				//let totalHeight = trigger.scrollHeight + answer.scrollHeight  + 'px';
				article.style.height = '50vw';
			}
		}
	}
}

const CheckHash = () => {
	let groupTarget = false;
	let hashTarget = false;
	let group = false;
	///1. is there a hash
	if(!window.location.hash) return;
	let hash = window.location.hash.substring(0); 
	hash =  hash.split('?')[0];
	//2. is there a match
	hashTarget = document.querySelector(hash);
	if(hashTarget == null) return;
	///3 is it in a group?
	let groups = document.querySelectorAll('.in-group');
	//yes, get target abd check if it the active group
	if(groups != null) {
		
		for (let j = 0; j < groups.length; j++){
			group = groups[j];
			hashTarget = group.querySelector(hash);
			if(hashTarget != null ) break;
			console.log('hashTarget', hashTarget);
		}

		if(hashTarget != null && hashTarget !== false && group != false) {
			///not active group, lets swap
			if(!group.classList.contains('active')) {
				groupTarget =  group.getAttribute('data-group');
				let idTarget = group.getAttribute('id');
				///get active group and link
			    let activeGroup =  document.querySelector('.active[data-group ="'+groupTarget+'"]');
				let activeLink =  document.querySelector('.active.show-hide-link');
				///target group and link
				let targetGroup = group;
				let targetLink = document.querySelector('.show-hide-link[href="#' + idTarget + '"]');
				console.log('group not active', idTarget, targetLink);
				//swict classes
				if(activeLink!=null) activeLink.classList.remove('active');
				if(activeGroup!=null) activeGroup.classList.remove('active');
				
				if(targetLink !=null) targetLink.classList.add('active');
				if(targetGroup !=null) targetGroup.classList.add('active');

			}
			

		}
	/// 4.open th target
	
	
	let trigger = hashTarget.querySelector('.question-title');
	let answer = hashTarget.querySelector('.answer');
	let numStage = hashTarget.classList.contains('entry-double') ? 'double' : 'single';
	console.log('hashTarget', hashTarget, numStage);
	initialOpen(hashTarget, trigger, answer, numStage);
	var scroll = new SmoothScroll();
	scroll.animateScroll(hashTarget);
	}

	

		


}
export {SetUpFaqs};
