import * as Odo from '../vendor/odometer'

let anim;
let entries;
let odArr = [];

const setUpNumbers =  () => {
   

	anim = document.getElementById('numbers-anim');
    if(anim === null ) return
    ['resize','scroll','load'].forEach( evt => 
		window.addEventListener(evt, requestFrame, false)
	 );
     entries = anim.getElementsByClassName('entry');
	
    for (let i = 0; i < entries.length; i++) {
        
        let entry = entries[i];
        let start = entry.dataset.start;
        let numberLength = start.length;
        let end = entry.dataset.end;
        let entry_amount = entry.querySelector('.entry--amount');
      
        
        let od = new Odometer({
        
            el: entry_amount,
            value: start,
            duration: 3000,
            numberLength: numberLength,
            theme: 'default'
            });	
        
        odArr.push( [od,end]);
        od.update(end);
    }


}

const runOd = () => {
    for (let i = 0; i < odArr.length; i++) {
       
        odArr[i][0].update(odArr[i][1]);
    } 

}
const checkOnScreen = () =>{
   let rect = anim.getBoundingClientRect();
   if( rect.top <= window.innerHeight) runOd();
}

	
function requestFrame () {
 
    window.requestAnimationFrame(() => checkOnScreen()) 
}


export {setUpNumbers};