

const lazyArray = [];
// feature detection
// https://github.com/Modernizr/Modernizr/blob/master/feature-detects/img/srcset.js

const srcset = document.body.classList.contains('srcset') || 'srcset' in document.createElement('img')

// device pixel ratio
// not supported in IE10 - https://msdn.microsoft.com/en-us/library/dn265030(v=vs.85).aspx

const dpr = window.devicePixelRatio || window.screen.deviceXDPI / window.screen.logicalXDPI;

const settings = {
  normal: 'data-normal',
  retina: 'data-retina',
  srcset: 'data-srcset',
  sizes: 'data-sizes',
  threshold: 0
}


const SetUpLazy = ($threshold = 0) => {
  

  lazyArray.length = 0;
  
  let imageItems =  document.querySelectorAll('[data-normal]');
	
  settings.threshold = $threshold;            
  
  for (let i = 0; i < imageItems.length; i++) {

		 lazyArray.push(imageItems[i]);
		 
	 
	  
  }

  SetUpEvents();
  requestFrame ();
}

const SetUpEvents = () => {

['resize','scroll','load'].forEach( evt =>  window.addEventListener(evt, requestFrame, false) );

}

function requestFrame () {

	window.requestAnimationFrame(() => isImageVisible())
 
  
}


const isVisible = ($element) => {
				  var rect = $element.getBoundingClientRect();
				  var h = (window.innerHeight || document.documentElement.clientHeight);
				  var w = (window.innerWidth || document.documentElement.clientWidth);
				  return (
					  rect.top <= h  && rect.left <= w && rect.right > 0 
				  );
		  };


const isImageVisible = () => {
  
	let spliceArray = [];
  
  if(lazyArray.length > 0){
	  
	lazyArray.forEach((object,index) => {
		  
		  var onScreen = isVisible(object);		
	  
		  if(onScreen) {
		  
			  //arr.splice(index, 1);
			  spliceArray.push(index);
			  
			  setSource(object);
			  setTimeout( () => { object.classList.add('lazy__show') }, 100);
		  }
			  
	  });
  
  
	  
	  for (let i = spliceArray.length - 1; i >=0 ; i--) {
		  
		  lazyArray.splice(spliceArray[i], 1);
	  }	
	  
  }	
				  
}



function setSource (node) {
  
  //instance.emit('src:before', node)
  // prefer srcset, fallback to pixel density
  if (srcset && node.hasAttribute(settings.srcset)) {
	node.setAttribute('srcset', node.getAttribute(settings.srcset))
  } else {
	const retina = dpr > 1 && node.getAttribute(settings.retina)
	if(retina || node.getAttribute(settings.normal))  node.setAttribute('src', retina || node.getAttribute(settings.normal))
  }
  ///sizes
  if (srcset && node.hasAttribute(settings.sizes)) {
	  node.setAttribute('sizes', node.getAttribute(settings.sizes));
  }
 // instance.emit('src:after', node)

  ;[settings.normal, settings.retina, settings.srcset, settings.sizes].forEach(attr => node.removeAttribute(attr))
  
 // update()
}




export {SetUpLazy, setSource, isImageVisible};