let wrapper,
 linkInfo, 
 linkGrid, 
 infoSection, 
 gridSection, 
 close,
 SmoothScroll

const SetUpProject = (ps, Slider, $SmoothScroll) =>{

    SmoothScroll = $SmoothScroll;
    wrapper = document.querySelector('.section--project-details');
    linkInfo =  document.querySelector('.link-info');
    linkGrid =  document.querySelector('.link-grid');
    infoSection = document.getElementById('info');
    gridSection = document.getElementById('grid');
    close = document.querySelector('.close-project-details');

   // console.log("set up project")
    linkInfo.onclick = () => slideUpWrapper("#info");
    linkGrid.onclick = () => slideUpWrapper("#grid");
    close.onclick = () => slideDownWrapper();

    let gridItems = document.querySelectorAll('.image-grid .image');
    for (let i = 0; i < gridItems.length; i++) {
   
        let image = gridItems[i];
        image.onclick = (e) => {

          //console.log(ps.updateOptions({"speed":1}));
           //Slider.speed = 0;
     
            Slider.goToSlide(i,ps);
            slideDownWrapper();

        }
    }
}

const slideUpWrapper = (section) => {
      console.log('slideupwrapper', section)
      let currentActive =  wrapper.querySelector('.is-active');
      let currentNavActive =  document.querySelector('.nav-active');
      if(currentActive != null) currentActive.classList.remove('is-active');
      if(currentNavActive != null) currentNavActive.classList.remove('nav-active');

      if(currentActive != null) currentActive.classList.remove('is-active');
      if(!wrapper.classList.contains('up'))  wrapper.classList.add('up');
     
      if(section=== "#grid" && !gridSection.classList.contains('is-active')) {
        gridSection.classList.add('is-active');
         linkGrid.classList.add('nav-active');

      }
      if(section=== "#info" && !infoSection.classList.contains('is-active')) {
        infoSection.classList.add('is-active');
        linkInfo.classList.add('nav-active');
      }
//    // if(!section.classList.contains('is-active')) section.calssList.add('is-active');
}

const slideDownWrapper = () =>{
    let currentActive =  wrapper.querySelector('.is-active');
    let currentNavActive =  document.querySelector('.nav-active');
    if(currentActive != null) currentActive.classList.remove('is-active');
    if(currentNavActive != null) currentNavActive.classList.remove('nav-active');
    if(wrapper.classList.contains('up'))  wrapper.classList.remove('up');
    //
    let scroll = new SmoothScroll();
    scroll.animateScroll(0);
}

export { SetUpProject };