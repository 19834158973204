import * as Fades from './imports/Fades';
import * as Lazy from './imports/Lazy';
import * as Faqs from './imports/Faqs';

import SmoothScroll from 'smooth-scroll';
import * as Slider from './imports/Slider-swiper';
import * as Numbers from './imports/Numbers'
import * as Instagram  from './imports/Instagram';
import * as Navbar  from './imports/Navbar';
import * as Video  from './imports/Video';
import * as Project from './imports/ProjectInfo'
import * as ProjectCategories from './imports/ProjectCategories'
import * as ShowHide from './imports/Showhide';

import { gsap } from "gsap";
import barba from '@barba/core';

barba.init({
	///prevent wordpress admin bar
	prevent: ({ el }) => el.classList && el.classList.contains('ab-item'),
	///Transitions
	transitions: [
	
	  {
		name: 'sync-transition',
		sync: true,
		from: {
			namespace: [
				'projects-listing'
			  ]
		},
		to: {
			namespace: [
				'project'
				]
		},
		leave(data) {
			//console.log('TRANSITION: sync --barba-leave');
			return gsap.to(data.current.container, {
				duration:.5,
				opacity: 1
			  });
			
		},

		enter(data) {
		//	console.log('TRANSITION: sync --barba-leave');
			let div = data.next.container;
			let wr = div.querySelector("#project-wrapper")
			return gsap.from(wr, {
				duration:.5,
				y:'100%',
				ease:'ease-out',
			
			  });
			
		},
		after(data) {
			window.scrollTo(0, 0);
		}
	
	  },
	  {
		name: 'sync-back',
		sync: true,
		from: {
			namespace: [
				'project'
			  ]
		},
		to: {
			namespace: [
				'projects-listing'
				]
		},
		leave(data) {
		//	console.log('TRANSITION: sync --barba-leave');
			
			let div = data.current.container;
			let wr = div.querySelector("#project-wrapper")
			return gsap.to(wr, {
				duration:.5,
				y:'100%',
				ease:'ease-out',
			  });
			
		},
		afterLeave(data) {
			data.next.container.classList.add('no-fade');
		},
		enter(data) {
		//	console.log('TRANSITION: sync --barba-leave');
			
			return gsap.from(data.next.container, {
				duration:.5,
				opacity: 1
			  });
			
		},
	
	  },
	  {
		name: 'opacity-transition',
		
		leave(data) {
		//	console.log('TRANSITION: opacity --barba-leave');
			
			  return gsap.to(data.current.container, {
				
				opacity: 0
			  });
			
		},
		enter(data) {
		//	console.log('TRANSITION: opacity --barba-enter');
			window.scrollTo(0, 0);
			data.current.container.remove();
			return gsap.from(data.next.container, {
				
				opacity: 0
			  });

			
		  }
	  },
	  {
		name: 'to-contact',
		to: {
			namespace: [
				'contact'
				]
		},
		leave(data) {
			//console.log('TRANSITION: to-contact --barba-leave');
		
			  return gsap.to(data.current.container, {
				duration:.5,
				opacity: 0
			  });
			
		},
		beforeEnter(data){
		
		},
		enter(data) {
		//	console.log('TRANSITION: opacity --barba-enter');
			window.scrollTo(0, 0);
			
			let body = 	document.querySelector('body');
			body.classList.add('invert');
			data.current.container.remove();
			
			return gsap.from(data.next.container, {
				duration:.5,
				opacity: 0
			  });

			
		  }
	  },
	  {
		name: 'leave-contact',
		from: {
			namespace: [
				'contact'
				]
		},
		leave(data) {
			//console.log('TRANSITION: to-contact --barba-leave');
			let body = 				document.querySelector('body');
			body.classList.remove('invert');
			  return gsap.to(data.current.container, {
				duration:.5,
				opacity: 0
			  });
			
		},
		enter(data) {
			//console.log('TRANSITION: opacity --barba-enter');
			window.scrollTo(0, 0);
			data.current.container.remove();
			return gsap.from(data.next.container, {
				duration:.5,
				opacity: 0
			  });

			
		  }
	  },
	
	
	],
	 
	views: [{
	  namespace: 'home',
	  beforeEnter() {
		// update the menu based on user navigation
		//menu.update();
		//console.log('VIEW: home --before-enter');
			
	  },
	  afterEnter() {
		// refresh the parallax based on new page content
		//console.log('VIEW: home --after-enter')

	  }
	}]
	
  });

barba.hooks.before( (data) => {
	console.log('HOOK --before')
	updateWordpressNav(data);
	
});

barba.hooks.after(() => {
	console.log('HOOK --after');
	App();
	ga('set', 'page', window.location.pathname);
	ga('send', 'pageview');
  });

const updateWordpressNav = (data) => {
	let body = 				document.querySelector('body');
	let namespace = data.current.namespace;
	let newNamespace = data.next.namespace;
	if(newNamespace != namespace){
		if(body.classList.contains(namespace)) body.classList.remove(namespace);
		if(!body.classList.contains(newNamespace)) body.classList.add(newNamespace);
	}
	// let li = data.trigger.parentNode;
	 let targetUrl = urlClean(data.next.url.href);
//	 console.log('unw',targetUrl);
	// let location = urlClean(window.location.toString());
	// console.log('data: ', data.next.url.href, 'url', window.location.toString());
	 let match = document.querySelector('.header--site a[href="'+targetUrl+'"]');
	 
	 let current = document.querySelector('.current_page_item');
	 let li = match.parentNode
	 //
	// console.log('unw',li, current);
	if(current != null) current.classList.remove('current_page_item');
	 li.classList.add('current_page_item');
	 //
	if(body.classList.contains('menu-visible')) body.classList.toggle('menu-visible');
	if(body.hasAttribute('style')) body.removeAttribute('style');
}

const urlClean = (url) => {
	clean_url = url;
	if (url.indexOf("?") > 0) {
        var clean_url = url.substring(0, url.indexOf("?"));
	}
	return clean_url;
}

const Nav = () => {
	let body = 				document.querySelector('body');
	let menu_button = 		document.querySelector('.menu-button');
	let menu_close = 		document.querySelector('.close-side-bg');
	let main = 				document.querySelector('#maincontent');
	let touch = 			false;
	//
	if(menu_button) menu_button.onclick = () =>  body.classList.toggle('menu-visible');
	if(menu_close) menu_close.onclick = () =>  body.classList.toggle('menu-visible');
	//
	Navbar.SetUpNavbar();
	//
	if ("ontouchstart" in document.documentElement) {
		body.classList.add("touch");
		touch = true;
	} else{
		body.classList.add("no-touch");
		touch = false;
	}
	if(main.getAttribute('data-barba-namespace') === 'contact') body.classList.add('invert');
	// let li = trigger.parentNode;
	// console.log('uwn',li)
	// let current = document.querySelector('current-menu-item');
	// current.classList.remove('current-menu-item');
	// if(!li.classList.contains('current-menu-item')) li.classList.add('current-menu-item')
}  

let AppCalled = false;

const App = (  ) => {
	AppCalled = true;
	//console.log('App');
	let i;
 	let body = 				document.querySelector('body');
	let updatecount = 		0;
	let formLoaded = 		false;
	let ps;
	//

    ///Fades
    Fades.SetUpFades('fades', 200);
    //Lazy
	Lazy.SetUpLazy();
	//
	if(document.getElementById("instagram-feed")!= null ) Instagram.SetUpInstagram("instagram-feed");
	//
	
	///acrolling
	//const scroll = new SmoothScroll('a[href*="#"]', {easing: 'easeOutQuad', speed:500});
	//

	if(document.getElementById('testimonials') != null )   Slider.SetUpTestimonials();
	if(document.getElementById('project-slider') != null )  ps =  Slider.SetUpProjectSlider();
	if(document.getElementById('logos') != null )  ps =  Slider.SetUpLogos();
	if(document.querySelector('.section--project-details') != null) Project.SetUpProject(ps, Slider, SmoothScroll);
	if(document.querySelector('.show-hide-link') != null) ShowHide.SetUpShowHide();
	if(document.getElementById('prices') != null) ShowHide.SetUpPriceToggle('show-ppa');
	if(document.querySelectorAll('.tax-link') != null) ProjectCategories.SetUpProjectCategories(Lazy);
	if( document.getElementById('numbers-anim') != null) Numbers.setUpNumbers();
	if(document.querySelectorAll('.video-holder') != null) Video.SetUpVideos();
	if(document.querySelector('.concertina') != null )  Faqs.SetUpFaqs(SmoothScroll);
	////
	body.classList.add('loaded');
      ///Ticker
    const fupdate = () => {
				
		updatecount++;
		if(updatecount > 10) {
			//console.log('update ');
			Fades.CheckFades();
			updatecount = 0;
		}

		if(formLoaded == false){
			let ninja = document.querySelectorAll('.wpcf7 input');
			
			if(ninja != null && ninja.length > 0) {
				formLoaded = true;
				setTimeout(fContactFormSevenForms, 300);
			}
			//console.log(ninja.length);
		}
		ticker = requestAnimationFrame(fupdate);


	}

    let ticker = requestAnimationFrame(fupdate);
	//window.addEventListener('scroll', fScroll);	 

	const fContactFormSevenForms = () =>{

		///Form label inputs
	//	console.log('c7 here');
		var inputs = document.querySelectorAll('.wpcf7 input, .wpcf7 textarea');
	
		var selects = document.querySelectorAll('.wpcf7 select');

		var ta = document.querySelector('.wpcf7 textarea');
		ta.setAttribute('rows',1);
		
		for ( i = 0; i < inputs.length; i++) {
			
			inputs[i].onfocus = (e) => {
				
				let input = e.currentTarget;
				let id = input.getAttribute('id');
				let selector = 'label[for="'+id+'"]';
			//	console.log('selectot',selector, 'id', id, 'input',input)
				let label = document.querySelector(selector);
				if(!label.classList.contains('focused')) label.classList.add('focused');
			}
		

			inputs[i].onblur = (e) => {
				let input = e.currentTarget;
				let id = input.getAttribute('id');
				let selector = 'label[for="'+id+'"]';
				let label = document.querySelector(selector);
				if(label.classList.contains('focused') && !input.value) label.classList.remove('focused');
			}
			
		}
		
		
		

				for ( i = 0; i < selects.length; i++) {
					
					
					selects[i].onfocus = (e) => {
						let input = e.currentTarget;
						let id = input.getAttribute('id');
						let selector = 'label[for="'+id+'"]';
						
						let label = document.querySelector(selector);
						if(!label.classList.contains('focused')) label.classList.add('focused');
					}
					selects[i].onblur = (e) => {
						let input = e.currentTarget;
						let id = input.getAttribute('id');
						let selector = 'label[for="'+id+'"]';
						let label = document.querySelector(selector);
						if(label.classList.contains('focused') && !input.value) label.classList.remove('focused');
					}
				}

		



	
	
	}
	/////synmaic text area
	document.addEventListener('input', function (event) {
		if (event.target.tagName.toLowerCase() !== 'textarea') return;
		autoExpand(event.target);
	}, false);

	var autoExpand = function (field) {

		// Reset field height
		field.style.height = 'inherit';
		var height = field.scrollHeight
		field.style.height = height + 'px';
	
	};
	let wr = document.querySelector('#project-wrapper')
	if(wr !== null){
		if(wr.hasAttribute('style')) {
			let st = wr.getAttribute('style');
			body.setAttribute('style', st);
		}

	} else {
		
	}
	
    return;
};

document.addEventListener("DOMContentLoaded",() => { 
	Nav();
	if(!AppCalled) App();
  });


//   wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
// 	$('.submit-success').fadeIn();
// 	setTimeout( function() {
// 		$('.submit-success').fadeOut();
// 	},5000);
// }, false );