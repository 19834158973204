
const SetUpShowHide = () => {
    let links = document.querySelectorAll('.show-hide-link');
    for(let i = 0; i < links.length; i ++){
        let link = links[i];
        /// add active to first link (php active is added in content-concertina)
        links[0].classList.add('active');
        link.onclick = (event) => {
            event.preventDefault();
            let $this = event.currentTarget;
            let linkID = $this.getAttribute('href');
           ////find section using ID
            let section = document.querySelector(linkID);
            ////find group it beloings to
            let group = section.getAttribute('data-group');
           ////find any currently active in group
            let activeSectionsInGroup =  document.querySelectorAll('.active[data-group ="'+group+'"]');
            let activeLinks =  document.querySelectorAll('.active.show-hide-link');
              ////remove active class 
            for(let j = 0; j <activeSectionsInGroup.length; j++ ){
               activeSectionsInGroup[j].classList.remove('active');
            }
            for(let j = 0; j <activeLinks.length; j++ ){
                activeLinks[j].classList.remove('active');
             }
            ///add active to selected section
            section.classList.add('active');
            $this.classList.add('active');
        }

    }

}

const SetUpPriceToggle = (initial) => {
    let monthly_prices = document.querySelectorAll('.monthly_price');
    let yearly_prices = document.querySelectorAll('.yearly_price');
    let cont = document.getElementById('prices');
    
    cont.classList.add(initial);

    for(let i = 0; i < monthly_prices.length; i ++){
       if(initial==='show-ppm') monthly_prices[i].classList.add('active');
        monthly_prices[i].onclick = (event) => {
            console.log('month')
            event.preventDefault();
            event.currentTarget.classList.add('active');
            if(!cont.classList.contains('show-ppm'))  {
                cont.classList.add('show-ppm');
            } else {
                return
            }
            if(cont.classList.contains('show-ppa'))  cont.classList.remove('show-ppa');
            //
            let activeyearly =document.querySelectorAll('.yearly_price.active');
            for(let j = 0; j < activeyearly.length; j ++) activeyearly[j].classList.remove('active');
        }
    }
    for(let i = 0; i < yearly_prices.length; i ++){
        if(initial==='show-ppa') yearly_prices[i].classList.add('active');
        yearly_prices[i].onclick = (event) => {
            console.log('year')
            event.preventDefault();
            event.currentTarget.classList.add('active');
            if(!cont.classList.contains('show-ppa')){
                cont.classList.add('show-ppa');
            } else {
                return
            }
            if(cont.classList.contains('show-ppm'))  cont.classList.remove('show-ppm');
            //
            let activemonthly =document.querySelectorAll('.monthly_price.active');
            for(let j = 0; j < activemonthly.length; j ++) activemonthly[j].classList.remove('active');
        }
    }
}

export {SetUpShowHide, SetUpPriceToggle};
