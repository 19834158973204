//import * as Tiny from '../vendor/tiny-slider';

//import Swiper, { Navigation }  from 'swiper';
//import swiper from 'swiper/bundle';

import Swiper, { Navigation, Keyboard, Autoplay } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Keyboard, Autoplay ]);


const SetUpTestimonials = (touch = false) => {
	
		let testimonialsTns = new Swiper('.swiper-container', {
  
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      on: {
        slideChange: (testimonialsTns) => {
        
          updateSlideNumbers(testimonialsTns);
      
        },

      }
    });
    
  return testimonialsTns;
}

const SetUpProjectSlider = () => {

  let projectsTns = new Swiper('.project-swiper-container', {
    centeredSlides:true,
    slidesPerView: "auto",
    spaceBetween: 100,
    initialSlide: 0,
    loop: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    on: {
      slideChange: (testimonialsTns) => {
      
        updateSlideNumbers(testimonialsTns);
    
      },

    }
  });
  //project-swiper-container

	//onChange(myTns);
	return projectsTns;
}

const SetUpLogos = (touch = false) => {
	
  let logosSlider = new Swiper('.swiper-container', {
    slidesPerView: 2,///was 5
    spaceBetween: 30,
    speed: 1000,
    loop: true,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    breakpoints: {
      // when window width is >= 320px
      
      // when window width is >= 480px
      480: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 5,
        spaceBetween: 40
      }
    }
  
  });
  
return logosSlider;
}

const updateSlideNumbers = (swiper,) => {
  let num = swiper.activeIndex;
  let el  = swiper.el;
	let indexCurrent = ('0' + (num + 1)).slice(-2)
//	slideCount =  ('0' + info.slideCount).slice(-2),
  let numCurrent = el.querySelector('.num_current');
  if(!numCurrent) numCurrent  = document.querySelector('#slidecount .num_current');

//	numTotal.textContent= slideCount;
	numCurrent.textContent = indexCurrent;
}

const goToSlide = (n, slider) => {
//	console.log('go to', slider)
	
	slider.slideTo(n,0);

	//

}

export {SetUpTestimonials, SetUpProjectSlider, SetUpLogos, goToSlide };
