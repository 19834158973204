const fadeArr =[];
const threshold = 100;

const SetUpFades = ($class) =>{

//	let threshold = $threshold;

	let fadeItems = document.getElementsByClassName($class);

        for (let i = 0; i < fadeItems.length; i++) {
	 		fadeItems[i].classList.add('el__hidden');

	 		fadeArr.push(fadeItems[i]);
	}


}


const CheckFades = () => {
	
	let spliceArray = [];
	
	fadeArr.forEach((object,index, arr) => {

    		//var onScreen = Visible.isVisible(fadeArr);
    		let rect = object.getBoundingClientRect();
    		let limit = window.innerHeight - threshold;
    		
    		if(rect.top <  limit) {
	    	
	    		
	    		if(object.classList.contains('el__hidden')) {
				object.classList.remove('el__hidden');
				object.classList.add('el__show');

			}

	    		spliceArray.push(index);
	    	}

	});
	
	
	for (let i = spliceArray.length - 1; i >=0 ; i--) {
		
		fadeArr.splice(spliceArray[i], 1);
	}


}



export {fadeArr, SetUpFades, CheckFades};
