import * as Visible from './Visible';

import Player from '@vimeo/player';

const vidArray = [];

const SetUpVideos = () => {

	let videoItems = document.getElementsByClassName('video-holder');
	
	for (let i = 0; i < videoItems.length; i++) {
		
   		 fInitVid(videoItems[i]);
   		
    }
 
}




window.addEventListener('scroll', event => {
       isVideoVisible();
});

window.addEventListener('resize', event => {
       isVideoVisible();
});


const fInitVid = ($vid) => {

	const id = $vid.getAttribute("data-id");
	if(id == null) return;
	const autoplay = $vid.getAttribute("data-autoplay");
	const autopause = $vid.getAttribute("data-autopause");
	
	const muted =  $vid.getAttribute("data-muted");
	const background =  $vid.getAttribute("data-background");
	const node_id = $vid.getAttribute("id");

	const controls = $vid.getAttribute("data-controls");
	let iframe;
	//let close =  $vid.getElementsByClassName('close-video');
	let playpause =  $vid.querySelector('.play-video');
	let fullscreen =  $vid.querySelector('.fullscreen');
	
	let use_controls = controls === "false" ? false : true;
	let ismute = muted=="false" ? false : true;
	let isBackground = background =="false" ? false : true;
	
	let player 
	
	$vid.classList.add('show-video');
	
	var options = {
        id: id,
        byline: false,
        portrait:false,
        title: false,
		autoplay: false,
		loop: true,
		controls: use_controls,
		background: isBackground,
		muted: ismute,
		};
	
   		player = new Player(node_id, options);
   		
   		player.ready().then(function() {
   		 	
			iframe = $vid.getElementsByTagName('iframe')[0];
			
			Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(function(dimensions) {
				let pw = dimensions[0];
				let ph = dimensions[1];
				let rat = (100 * (ph/pw)).toFixed(2);
				$vid.setAttribute('style', '--rat:'+rat+'%');
			})
			
			
			$vid.classList.add('video-ready');
			///if data-autoplay = autoplay
			
			if(autoplay == "autoplay"){
				
				let onScreen = Visible.isVisible(iframe);
			//	 console.log('onScreen',onScreen)
				 if((onScreen) || (autopause == "false")) {
					//console.log('play?')
					 fPlayPause(player,playpause);
				}
				 
				 
			}
			//
			vidArray.push({iframe, player, playpause, id, $vid, node_id});
			
		}, function(err){
			console.log(err);
			return false;
		});
		
		
		
		player.on('bufferstart', (data) => {
			
		})
		player.on('play', (data) => {
			if(!playpause.classList.contains('is-playing')) playpause.classList.add('is-playing');
		});
		//
		player.on('pause', (data) => {
			if(playpause.classList.contains('is-playing')) playpause.classList.remove('is-playing');
		});
		
		////Play pause
		if(playpause != null) playpause.onclick = () => fPlayPause(player,playpause);	
		if(fullscreen != null) fullscreen.onclick = () => fFullscreen($vid, player);	
		return player;	
}

const fPlayPause = (player, playpause) =>{

	player.getPaused().then(function(paused) {
			    // paused = whether or not the player is paused
				
			    if(paused) {
				  
				     	vimPlay(player, playpause);
				     
				     } else{
					     
					     vimPause(player, playpause);
				     }
			}).catch(function(error) {
			    // an error occurred
			});
	
}

const fFullscreen = (holder, player) =>{
	//console.log('requetfull')
	let full = document.querySelector('#fullscreen-holder');
	let fullScreenPlayer = fInitVid(full);

	fullScreenPlayer.requestFullscreen().then(function() {
		// the player entered fullscreen
	}).catch(function(error) {
		// an error occurred
		console.log(error);
	});
	fullScreenPlayer.on('fullscreenchange', function(data) {
		if(data.fullscreen == false) destroyPlayer(fullScreenPlayer);
		
		// data is an object containing properties specific to that event
		//destroyPlayer(fullScreenPlayer );
	});

}

const vimPause = (player, playpause) => {
	
	player.pause().then(function() {
		if(playpause != undefined) {
					     	
		 if(playpause.classList.contains('is-playing')) playpause.classList.remove('is-playing');
	 }
	}).catch(function(error) {
    switch (error.name) {

        case 'PasswordError':
            // the video is password-protected and the viewer needs to enter the
            // password first
            break;

        case 'PrivacyError':

            break;

        default:
            // some other error occurred
            break;
    }
});
}

const vimPlay = (player, playpause) => {
	
	//$vid.classList.add('video-ready');
	player.play().then(function() {
    // the video was played
    	
	if(playpause != undefined) {
		
		if(!playpause.classList.contains('is-playing')) playpause.classList.add('is-playing');
	}
   
	}).catch(function(error) {
		console.log(error);
    switch (error.name) {
        case 'PasswordError':
            // the video is password-protected and the viewer needs to enter the
            // password first
            break;

        case 'PrivacyError':
            // the video is private
            break;

        default:
            // some other error occurred
            break;
    }
});
}

const isVideoVisible = () => {

	let spliceArray = [];
	
	vidArray.forEach((arr, index, object) => {
			///sliders remove the element so we should destry the player
			let originalElement = arr.player._originalElement;
			if(originalElement == undefined) spliceArray.push(index);
    		//console.log(arr.node_id);
    		if(arr.iframe != undefined) var onScreen = Visible.isVisible(arr.iframe);
			
    		if(!onScreen) {
	    		
	    		
	    		let hasAutopause = arr.$vid.getAttribute('data-autopause');
	    		    		
	    		
	    		if(hasAutopause == null || hasAutopause!= "false")	 {  
		    		
					vimPause(arr.player);
		    		let playpause = arr.playpause
		    		 
		    		if(playpause != undefined) {
		    		 	playpause.setAttribute('data-copy', 'Play video');
					 	if(playpause.classList.contains('is-playing')) playpause.classList.remove('is-playing');
					 }
		    		///
		    		spliceArray.push(index);
	    		}
	    	
	    	}

	});


	for (let i = spliceArray.length - 1; i >=0 ; i--) {
		
		vidArray.splice(spliceArray[i], 1);
	}

}

const destroyPlayer = (player, arrayIndex=null, $vid=null, $replace=null) => {
	player.destroy().then(function() {
		    // the player was destroyed
		   
		    ///remove the classes added
		    if($vid!=null) $vid.classList.remove('show-video','video-ready');
		    ////if we're replacing add new object here
		    if($replace!=null) fInitVid($replace);
		    ///remove fro the array
			if(arrayIndex!=null) vidArray.splice(arrayIndex, 1);
		   
		}).catch(function(error) {
		    // an error occurred
		});
} 





export {SetUpVideos,  fInitVid, vimPlay};
