//import InstagramFeed from '../vendor/InstagramFeed.min'
let element;
const SetUpInstagram = ($id) =>{
  
    let instagramWrapper =  document.getElementById($id);
   
    // new InstagramFeed({
	// 	'username': 'pinchpoint_ldn',
	// 	'display_profile': false,
	// 	'display_biography': false,
    //     'display_gallery': true,
    //     'items': 4,
    //     'callback': getCaps,
      
	// 	'styling': false,
	// 	'items_per_row': 4,
	// 	'margin': 1,
	// 	'lazy_load': true,
	// 	'on_error': console.error,
    //     'host': 'https://images' + ~~(Math.random() * 3333) + '-focus-opensocial.googleusercontent.com/gadgets/proxy?container=none&url=https://www.instagram.com/'
	// });
	
    fetch('/wp-json/pinchpoint/instagram-feed')
    .then((r) => r.json()
    .then((json) => {
        let items = [...json.posts].slice(0, 4);
        //console.log('items', items)
        for (const item of items) {
            if (item.type === 'IMAGE') {
              let postSlide = document.createElement('div');
              postSlide.classList.add('entry--instagram');
              let caption = item.caption;
              if(caption.length > 180 ) {
                            
                caption = caption.substring(0, 180);
                let n = caption.lastIndexOf(' ');
                caption = caption.substring(0, n) + '&hellip;';
            }
              postSlide.innerHTML = `<a class="image" href="${item.url}" target="_blank" rel="noopener"><img src="${item.image_url}" /></a><div class="caption">${caption}</div>`;
              instagramWrapper.appendChild(postSlide);
            }
            // if (item.type === 'VIDEO') {
            //   let postSlide = document.createElement('div');
            //   postSlide.classList.add('entry--instagram');
            //   let caption = item.caption;
            //   if(caption.length > 180 ) {
                            
            //     caption = caption.substring(0, 180);
            //     let n = caption.lastIndexOf(' ');
            //     caption = caption.substring(0, n) + '&hellip;';
            // }
            //   postSlide.innerHTML = `<a class="image video" href="${item.url}" target="_blank" rel="noopener"><video class="insta-video" width="500" height="500" loop autoplay muted playsinline><source src="${item.image_url}" type="video/mp4"></video></a><div class="caption">${caption}</div>`;
            //   instagramWrapper.appendChild(postSlide);
            // }
          }

     }));
        //
  
      



}


const getCaps = (data) =>{
   // console.log(data);
   let nodes = data.edge_owner_to_timeline_media.edges;
   nodes  = nodes.slice(0, 4);
   let $html ='';
   let options = {  year: 'numeric', month: 'long', day: '2-digit' };

   nodes.map(item => {
       //  console.log('node', item.node);	
        // 
        let node = item.node;
        let link = "https://www.instagram.com/p/"+node.shortcode+"/",
            image_url = node.thumbnail_src,
            comments = node.edge_media_to_comment.count,
            caption = node.edge_media_to_caption.edges[0].node.text,
            created = node.taken_at_timestamp,
            timestamp = new Date(created * 1000).toLocaleDateString('en-GB',options);
            ////

            //caption = caption.length > 180 ?   caption.substring(0, 180) + '&hellip;' : caption;
            if(caption.length > 180 ) {
                
                caption = caption.substring(0, 180);
                let n = caption.lastIndexOf(' ');
                caption = caption.substring(0, n) + '&hellip;';
            }

            $html += '<div class="entry--instagram"><a href="'+link+'" target="_blank" class="image"> <img src="'+image_url+'" /></a> <div class="instagram-info"><p><span class="date">'+timestamp+'</span><span class="caption">' + caption+ '</span></p><a href="'+link+'" class="instagram-link">See more</a></div></div>';

    });	
    element.innerHTML = $html;
}


export { SetUpInstagram };