let navbar = document.querySelector('.navbar');
let header = navbar.querySelector('.header--site');
let previousScrollY = window.pageYOffset;
let navbarOffset = 0;
let snapTimeout;
let restoreTimeout;
///for hidden footer
let footer = 			document.querySelector('.site-footer');
let body = 				document.querySelector('body');

const SetUpNavbar = () =>{
  
     window.addEventListener('scroll', function(){
        requestAnimationFrame(checkScrollDirection);
        
    });
    checkScrollDirection();
}

const setNavbarOffset = (newNavbarOffset) => {
    navbarOffset = Math.max(Math.min(newNavbarOffset, header.offsetHeight), 0);
    navbar.style.transition = 'transform 0.5s';
    navbar.style.top = `${-navbarOffset}px`;
    clearTimeout(restoreTimeout);
    restoreTimeout = setTimeout(() => {
      navbar.style.transition = 'transform 0.5s, top 0.3s';
    });
  }


const checkScrollDirection = () =>  {


    ///for hidden footer
    let footerDiff = window.innerHeight - body.getBoundingClientRect().bottom;

    if(footerDiff < 0 ) {
      const pageY = Math.max(0, window.pageYOffset);
      const scrollAmount = pageY - previousScrollY;
     setNavbarOffset(navbarOffset + scrollAmount);
      clearTimeout(snapTimeout);
     snapTimeout = setTimeout(() => {
      navbarOffset = navbarOffset > (header.offsetHeight / 2) && window.pageYOffset > header.offsetHeight ? header.offsetHeight : 0;
      navbar.style.top = `${-navbarOffset}px`;
    }, 500);
    
    previousScrollY = pageY;
    //
    //console.log(body.getBoundingClientRect().top);
    if(body.getBoundingClientRect().top > -104){
      if(!body.classList.contains('navtop')) body.classList.add('navtop');
    } else {
      if(body.classList.contains('navtop')) body.classList.remove('navtop');
    }
    //footer
    navbar.style.position = 'fixed';
    //footer
    } else {
      
       navbar.style.position = 'absolute';
    }

   
      if(footerDiff > -300 ) {
        footer.style.visibility = 'visible';
        //navbar.style.top = '-104px';
       
      } else {
        footer.style.visibility = 'hidden';
      }
  };


  const fupdate = () => {
				
    let footerDiff = window.innerHeight - body.getBoundingClientRect().bottom + 300;
    ticker = requestAnimationFrame(fupdate);
    //console.log('footerDiff', footerDiff)
    if(footerDiff > -300 ) {
      footer.style.visibility = 'visible';
      //navbar.style.top = '-104px';
     
    } else {
      footer.style.visibility = 'hidden';
    }

    if(footerDiff > 299 ) {
      footer.style.zIndex = 0;
    } else {
      footer.style.zIndex = -1;
    }

	}

let ticker = requestAnimationFrame(fupdate);

export { SetUpNavbar, checkScrollDirection };