let loading = false;
let Lazy;

///project-category/residential/

const SetUpProjectCategories = ($lazy) => {
	Lazy = $lazy		
    let cats = document.querySelectorAll('.tax-link');

	///
	for(let i = 0; i < cats.length; i++){
		let cat = cats[i];
		if(cat.classList.contains('tax-all')) cat.classList.add('active');
		cat.onclick = (e) => {
			e.preventDefault();
        //	if(loading === true) return;
            let clickedCat = e.currentTarget;
            let href = '/project-category/' + cat.getAttribute('data-tax');
            if(clickedCat.classList.contains('tax-all')) href = '/projects/';
			let activeCat = document.querySelector('.tax-link.active');
		
			if(activeCat != null) activeCat.classList.remove('active');
			clickedCat.classList.add('active');
			//
            loadCategories(href);
            
		}
	}
}




const loadCategories = ($href = null, $pag = false) => {
	
		//if(loading === true) return;
        if($href===null) return;
        
		loading = true;
	
			//href = $href; 
		let holder = document.getElementById('load-holder');
        let base = holder.getAttribute('data-base');
        let href  = (base != null) ? base + $href : $href;
      
        if($pag){
            holder.classList.add('loading-more');
        } else {
            holder.innerHTML = "";
            holder.classList.add('loading-content');
        }
		let h = holder.clientHeight;
		holder.style.height = h + 'px';
		

		///get page
		let  xhr = new XMLHttpRequest();
		xhr.open('POST', href, true);
		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
		xhr.onload = function() {
		  if (this.status >= 200 && this.status < 400) {
			    // Success!
			 	loading = false;
			    let resp = this.response;
				let dummy = document.createElement('html'); 
			
				///get the modal content for the response
				dummy.innerHTML = resp;
				let content = dummy.querySelector('#load-holder .load-content');
			     //add content to modal
			    holder.appendChild(content);
			    if(holder.classList.contains('loading-content')) holder.classList.remove('loading-content');
			    if(holder.classList.contains('loading-more')) holder.classList.remove('loading-more');
				//
				let nh; 
				if($pag){
					// the 100 is the height of the pagination that we get rid of
					nh = content.clientHeight  + h - 100;	
				} else  {
			    	nh = content.clientHeight;
			    }
				holder.style.height = nh + 'px';
				content.classList.add('fade-in');
				//fSetUpPagination(content);
				//setUpMobileJobs();
				Lazy.SetUpLazy();
				
		     } else {
		    //
		
		  }
		};
		
		xhr.onerror = function() {
		  // There was a connection error of some sort
		};
		
		xhr.send();
	}


	///




export {SetUpProjectCategories};